
        import V from 'voUtils/V.js';
        import Vue from 'vue';
        import {voMsg,voMsgPl} from './voUtils/voI18n.js';
        import DateEx from './voUtils/DateEx.js';

        Vue.prototype.VOFFICE = window.VOFFICE;

        var voFormatDate = DateEx.formatDate;

        import AirbnbStyleDatepicker from 'base/vue-airbnb-style-datepicker'
        import 'base/vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'

        var vueApps = {};

        var methods = {voMsg,voMsgPl,voFormatDate};

        
            import App0 from './app/dummy.js';
            vueApps['app/dummy.js'] = App0;
        

            import App1 from './app/unit.js';
            vueApps['app/unit.js'] = App1;
        

            import App2 from './app/search.js';
            vueApps['app/search.js'] = App2;
        

            import App3 from './app/navbar.js';
            vueApps['app/navbar.js'] = App3;
        

        
                import Comp4 from './comp/CookieBox.vue';
                Vue.component('voffice-cookie-box',Comp4);
            

                import Comp5 from './comp/ImgFallback.vue';
                Vue.component('img-fallback',Comp5);
            

                import Comp6 from './comp/PhotoSwipe.js';
                Vue.component('voffice-photoswipe',Comp6);
            

                import Comp7 from './comp/MaxTextSize.js';
                Vue.component('voffice-max-text-size',Comp7);
            

                import Comp8 from './comp/DateRange.vue';
                Vue.component('voffice-daterange',Comp8);
            

                import Comp9 from './comp/FavElement.vue';
                Vue.component('voffice-fav-element',Comp9);
            

                import Comp10 from './comp/Booking.js';
                Vue.component('voffice-booking',Comp10);
            

                import Comp11 from './comp/ErrorMessage.vue';
                Vue.component('voffice-error-message',Comp11);
            

                import Comp12 from './comp/DocBox.vue';
                Vue.component('voffice-doc-box',Comp12);
            

                import Comp13 from './openstreetmap.vue';
                Vue.component('voffice-openstreetmap',Comp13);
            

                import Comp14 from './comp/gmap.js';
                Vue.component('voffice-gmap',Comp14);
            

                import Comp15 from './comp/GuestBox.vue';
                Vue.component('voffice-guestbox',Comp15);
            

                import Comp16 from './comp/PopupBox.vue';
                Vue.component('voffice-popup-box',Comp16);
            

                import Comp17 from './comp/SearchFilterTokens.vue';
                Vue.component('search-filter-tokens',Comp17);
            

                import Comp18 from './comp/starDisplay.vue';
                Vue.component('star-display',Comp18);
            

                import Comp19 from './comp/Paginate.vue';
                Vue.component('voffice-paginate',Comp19);
            

                import Comp20 from './comp/carousel.vue';
                Vue.component('owl-carousel',Comp20);
            

                import Comp21 from './comp/ContactForm.js';
                Vue.component('voffice-form',Comp21);
            

                import Comp22 from './comp/Voucher.vue';
                Vue.component('voffice-voucher',Comp22);
            

        Vue.mixin({
            methods: methods
        });

        const datepickerOptions = {
            days: voMsg('weekdayslist').split(','),
            daysShort: voMsg('weekdayslist.short').split(','),
            monthNames: voMsg('monthlist.full').split(',')
        };
        Vue.use(AirbnbStyleDatepicker, datepickerOptions);


    	var passThrough = function (val) {
			return val || '';
		};

    	var filterCodes = [
    	    'vlang',
    	    'vautolang',
    	    'vsoption',
    	    'vsPropOption'
    	  ];

        for (let fc of filterCodes) {
    		Vue.filter(fc, passThrough);
    	};


        var vueAppEls = document.body.querySelectorAll('[vo-vue-app]');

        for (let el of vueAppEls) {
            let vName = el.getAttribute("vo-vue-app");
            let v = vueApps[vName];
            if (v) {
                v.el = el;
                V.log("creating vue app",vName);

                let app = new Vue(v);
            }

        }

        VOFFICE.afterAllLoaded = function(){
            document.documentElement.classList.add("app-loaded");
        };

        window.addEventListener('beforeunload', function (event) {
        	document.documentElement.classList.add("app-leaving");
        });


    