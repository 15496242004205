<script>
    import V from 'voUtils/V.js'



    export default {
    	voVueComponent: 'owl-carousel',
    	props: {
    		carouselId: String
    	},

    	mounted: function() {
    		$("#owl-" + this.carouselId).owlCarousel({
    			items: 1,
    			loop: true,
    			autoplay: true,
    			dots: false,
    			autoplayTimeout: 8000,
    			smartSpeed: 1000,
    			nav: false
    		});
    	}
    };
</script>